/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
// import { MessageBox } from 'mint-ui';
// import { showConfirmDialog } from "vant";
export default {
  name: "Add",
  data() {
    return {
      imgs: []
    };
  },
  methods: {
    fileUpload() {
      let that = this;
      let file = document.getElementById("upfile");
      let fileName = file.value;
      let files = file.files;
      console.log(files[0]);
      if (fileName == null || fileName == "") {
        alert("请选择文件");
      } else {
        let fileType = fileName.substr(fileName.length - 4, fileName.length);
        if (files[0]) {
          let formData = new window.FormData();
          formData.append("file", files[0]);
          formData.append("mod", 5);
          formData.append("opt", 2);
          let data = {
            mod: 5,
            opt: 2,
            formData
          };
          fetch("/upload", {
            method: "POST",
            body: formData,
            headers: {
              // Auth: 'token'
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer "
            }
          }).then(res => {
            return res.json();
          }).then(res => {
            console.log(res);
            if (res.sta == 0) {
              // 上传代码返回结果之后
              // console.log(res.data)
            } else {
              console.log(res.msg);
            }
          });
          let reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.onload = function (e) {
            let timestamp = new Date().valueOf();
            that.imgs.push({
              id: timestamp,
              base64: this.result
            });
            console.log(that.imgs);
          };
        } else {
          alert("请选择要上传的图片");
        }
      }
    },
    handleDeleteImage(id) {
      let that = this;
      this.$dialog.confirm({
        mes: "确定删除该图片吗?",
        opts: [{
          txt: "取消",
          color: false
        }, {
          txt: "确定",
          color: true,
          callback: () => {
            deleteImage();
          }
        }]
      });
      //   MessageBox.confirm('确定删除该图片吗?').then(action => {
      //     console.log(action)
      //     if(action == 'confirm') {
      //       deleteImage()
      //     }
      //   });
      function deleteImage() {
        for (let i = 0; i < that.imgs.length; i += 1) {
          if (that.imgs[i].id === id) {
            that.imgs.splice(i, 1);
            break;
          }
        }
      }
    }
  }
};